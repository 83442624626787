<template>
  <v-layout fill-height>
    <v-row justify="center" align="center">
      <v-col cols="12" sm="8" md="6" lg="4">
        <v-card ref="form" max-width="370px">
          <v-img
            class="white--text align-end"
            src="../assets/docks.jpg"
            height="180px"
          >
            <v-card-title>FTMount OMS</v-card-title>
          </v-img>
          <v-card-subtitle>请输入登录账号和密码</v-card-subtitle>
          <v-card-text>
            <v-text-field
              v-model="username"
              label="Name"
              :rules="[nameRules.required, nameRules.min]"
            ></v-text-field>
            <v-text-field
              v-model="password"
              :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[passwordRules.required, passwordRules.min]"
              :type="showPass ? 'text' : 'password'"
              label="Password"
              hint="至少8个字符！"
              @click:append="showPass = !showPass"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn block @click.prevent="handleLogin">
              登 录
            </v-btn>
          </v-card-actions>
          <v-divider></v-divider>
          <v-card-text class="text-center">
            &copy; 2020 杭州方田进出口有限公司
          </v-card-text>
          <v-overlay :absolute="true" :value="alert">
            <v-btn color="success" @click="alert = false">
              {{ message }}
            </v-btn>
          </v-overlay>
        </v-card>
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
import User from "../models/user";

export default {
  data: () => ({
    username: "",
    email: "",
    password: "",
    showPass: false,
    nameRules: {
      required: value => !!value || "必填！",
      min: v => v.length >= 3 || "至少3个字符！"
    },
    passwordRules: {
      required: value => !!value || "必填！",
      min: v => v.length >= 8 || "至少8个字符！"
    },
    user: new User("", ""),
    message: "",
    alert: false
  }),

  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },

  created() {
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },

  methods: {
    handleLogin() {
      if (this.username && this.password) {
        this.user.username = this.username;
        this.user.email = this.email;
        this.user.password = this.password;
        this.$store.dispatch("auth/login", this.user).then(
          () => {
            this.$router.push("/");
          },
          error => {
            console.log(error);
            this.message = "登录失败！请检查你输入的用户名或密码是否有误。";
            this.alert = true;
          }
        );
      }
    }
  }
};
</script>
